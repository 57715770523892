<template>
  <div class="vehicles">
    <lenon-list-page
        title="Vehicles"
        :columns="columns"
        :rows="vehicles"
        :table-loading="tableLoading"
        :show-create="false"
        :show-refresh="false"
        :show-search="true"
        :show-details="true"
        search-placeholder="Search"
    >
      <template slot="table-header">
        <div
            v-responsive="$store.getters['app/smallScreen']"
            class="col-12"
        >
          <b-collapse
              id="vehicle"
          >
            <div class="row">
              <div class="col-md-6">
                <lenon-button
                    icon="SettingsIcon"
                    label="Vehicles"
                    tool-tip-text="Manage Vehicles(CRUD)"
                    class="mb-1"
                    @onClick="showVehiclesModal()"
                />
              </div>
            </div>
          </b-collapse>
        </div>
      </template>
      <template slot="right-extra-header-actions">
        <div
            v-responsive="$store.getters['app/largeScreen']"
            class="d-flex align-self-center align-items-start"
        >
          <lenon-button
              icon="SettingsIcon"
              label="Vehicles"
              tool-tip-text="Manage Vehicles(CRUD)"
              class=""
              @onClick="showVehiclesModal()"
          />
        </div>
        <div
            v-responsive="$store.getters['app/smallScreen']"
            class="d-flex align-self-center align-items-center"
        >
          <lenon-button
              v-b-toggle.vehicle
              icon-only
              icon="MoreVerticalIcon"
              variant="flat-primary"
          />
        </div>
      </template>
    </lenon-list-page>

    <vehicles-setup
        :modal-opened="vehiclesModalOpened"
        @modalClosed="closeModal('vehicles')"
    />
  </div>
</template>

<script>
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import VehiclesSetup from '@/views/transportation/settings/vehicles/VehicleSetup.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'
import { VBToggle, BCollapse, BBadge } from 'bootstrap-vue'
import LenonSelect from '@/lenon/components/LenonSelect'

export default {
  name: 'Vehicles',
  components: {
    LenonSelect,
    LenonDropdownItem,
    LenonDropdown,
    LenonListPage,
    VehiclesSetup,
    LenonButton,
    BBadge,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [showToast],
  data() {
    return {
      vehiclesModalOpened: false,
      tableLoading: false,
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'label',
          label: 'Label',
        },
        {
          key: 'number',
          label: `Vehicle Number`,
        },
      ],
    }
  },
  computed: {
    vehicles() {
      return this.$store.getters['vehicle/vehicles']
    },
  },
  watch: {
  },
  mounted() {
    this.fetchVehicles()
  },
  methods: {
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'vehicles':
          this.vehiclesModalOpened = false
          break
      }
    },
    showVehiclesModal() {
      this.vehiclesModalOpened = true
    },

    fetchVehicles() {
      this.tableLoading = true
      this.$http.get("transportation/vehicles").then(res => {
            this.$store.commit('vehicle/setVehicles', res.data)
          })
          .catch(err => {
            this.showError('Failed to load fee vehicles')
          }).finally(() => {
        this.tableLoading = false
      })
    },
  },
}
</script>
